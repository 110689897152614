import { useEffect } from "react";
import "./app.css";
import "./custom.css";

export default () => {
  useEffect(() => {
    // Specify the URL you want to redirect to
    const url = 'https://www.oldmatesburgers.co.nz/';
    window.location.href = url;
  }, []); // The empty array causes this effect to only run on mount

    return (
        <>
          <div>Redirecting...</div>
        </>
    );
};
